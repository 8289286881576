import { createSelector } from '@ngrx/store';
import { IRootState } from '../../../store';
import { TypeAction, ActionTypes } from './tasks-agreement.actions';

export interface IAgreementViewState {
  firstName: string;
  lastName: string;
  email: string;
  school: string;
  preferredPhoneNumber: string;
  alternatePhoneNumber: string;
  address: {
    zip: string;
    addressLine: string;
    addressLine2: string;
    city: string;
    state: string;
    province: string;
  };
  emergencyContact: {
    firstName: string;
    lastName: string;
    primaryPhoneNumber: {
      phoneNumber: string;
      typeId: number;
    } | null;
    alternatePhoneNumber: {
      phoneNumber: string;
      typeId: number;
    } | null;
    relationshipId: number;
    relationship: string;
  };
}

export interface IState {
  agreementInfo: IAgreementViewState;
}

const initialState: IState = {
  agreementInfo: {
    firstName: '',
    lastName: '',
    email: '',
    school: '',
    preferredPhoneNumber: '',
    alternatePhoneNumber: '',
    address: {
      zip: '',
      addressLine: '',
      addressLine2: '',
      city: '',
      state: '',
      province: '',
    },
    emergencyContact: {
      firstName: '',
      lastName: '',
      primaryPhoneNumber: null,
      alternatePhoneNumber: null,
      relationshipId: 0,
      relationship: '',
    },
  }
};

export function agreementTaskReducer(state: IState = initialState, action: TypeAction): IState {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.STORE_AGREEMENT: {
      return Object.assign({}, state, { agreementInfo: payload });
    }

    default: {
      return state;
    }
  }
}

export const getAgreementTaskInfo = createSelector(
  (root: IRootState): IState => root.agreementTask,
  ({ agreementInfo }: IState): IAgreementViewState => agreementInfo
);
