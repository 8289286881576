import { Action } from '@ngrx/store';
import { type } from '../../../util';
import { IAgreementViewState } from './tasks-agreement.reducer';

export const ActionTypes = {
  FETCH_AGREEMENT: type('[Tax document form] fetch agreement view info'),
  STORE_AGREEMENT: type('[Tax document form] store agreement view info'),
};

export class FetchAgreementInfo implements Action {
  public type = ActionTypes.FETCH_AGREEMENT;

  constructor(public payload?: any) {}
}

export class StoreAgreementInfo implements Action {
  public type = ActionTypes.STORE_AGREEMENT;

  constructor(public payload: IAgreementViewState) {}
}

export type TypeAction =
  FetchAgreementInfo |
  StoreAgreementInfo ;
